
        import { createElement as _createElement } from 'react';
import { Fragment as _Fragment } from 'react';
import _map from 'lodash-es/map';
export default function relatedItemsRT () {
    function repeatItems1(items, itemsIndex) {
        return [items(function () {
                function mergeProps(inline, external) {
                    var res = Object.assign({}, inline, external);
                    if (inline.hasOwnProperty('style')) {
                        res.style = _.defaults(res.style, inline.style);
                    }
                    if (inline.hasOwnProperty('className') && external.hasOwnProperty('className')) {
                        res.className = external.className + ' ' + inline.className;
                    }
                    return res;
                }
                return _createElement('div', { 'className': 'zoom-fade-animation-element-wrapper grid__item product-item-block view-mode-grid medium-up--one-quarter small--one-half' }, _createElement('div', { 'className': 'product-card product-card-wrapper' }, _createElement('div', { 'className': 'product-item__label-list' }, this.on_sale ? _createElement('span', {
                    'className': 'product-label product-label--on-sale',
                    'key': '233'
                }, '\n        ', Math.round((1 - this.price / this.compare_at_price) * 100), '% Off\n      ') : null), _createElement('div', { 'className': 'product-item--media' }, _createElement('div', { 'className': 'product-card__image-with-placeholder-wrapper' }, _createElement('a', {
                    'className': 'list-view-item__link-image product-card__link-image',
                    'href': this.url
                }, _createElement('div', { 'className': 'list-view-item__image-wrapper product-card__image-wrapper' }, _createElement('div', {}, _createElement('img', {
                    'className': 'list-view-item__image product-card__image zoom-fade-animation-element',
                    'src': this.imageOrDefault(this.resizeImage(this.image)),
                    'alt': this.removeHTML(this.title),
                    'onError': this.onImageError
                })))))), _createElement('div', { 'className': 'product-item--info' }, _createElement('div', {}, _createElement('a', mergeProps({
                    'className': 'product-item__vendor link',
                    'href': this.vendor_url
                }, { dangerouslySetInnerHTML: { __html: this.vendor } }))), _createElement('a', {
                    'className': 'item__link-title product-card__link-title',
                    'href': this.url,
                    'aria-label': this.title
                }, _createElement('span', mergeProps({ 'className': 'h4 item__title product-card__title' }, { dangerouslySetInnerHTML: { __html: this.title } }))), _createElement('div', { 'className': 'product-item__price_and_reviews_row' }, _createElement('div', { 'className': 'price price--listing' + (this.on_sale ? ' price--on-sale' : '') }, !this.on_sale ? _createElement('div', {
                    'className': 'price__regular',
                    'key': '1595'
                }, this.price_varies ? _createElement('span', { 'key': '1658' }, 'From: ') : null, _createElement('span', { 'className': 'price-item price-item--regular' }, this.formatPrice(this.price))) : null, this.on_sale ? _createElement('div', {
                    'className': 'price__sale',
                    'key': '1826'
                }, _createElement('span', { 'className': 'price-item price-item--sale' }, this.formatPrice(this.price)), _createElement('div', { 'className': 'price__compare' }, _createElement('s', { 'className': 'price-item price-item--regular' }, this.formatPrice(this.compare_at_price)))) : null)), _createElement('button', {
                    'type': 'button',
                    'className': 'btn btn--primary open-quick-view--btn',
                    'aria-controls': 'modal-quick-view',
                    'data-product-url': this.url
                }, _createElement('span', {}, 'Quick view'), _createElement('svg', {
                    'className': 'icon icon--tail-right',
                    'viewBox': '0 0 24 24',
                    'role': 'presentation'
                }, _createElement('path', {
                    'fill': 'currentColor',
                    'd': 'M22.707 11.293L15 3.586 13.586 5l6 6H2c-.553 0-1 .448-1 1s.447 1 1 1h17.586l-6 6L15 20.414l7.707-7.707c.391-.391.391-1.023 0-1.414z'
                }))))));
            }, { count: undefined })];
    }
    return _createElement('div', { 'className': 'product-recommendations__inner half_row_mobile' }, _createElement('div', { 'className': 'section-header text-center' }, _createElement('h2', {}, 'You may also like')), _createElement.apply(this, [
        'div',
        { 'className': 'zoom-fade-animation grid grid--uniform grid--view-items use_align_height zoomFade-animation cmRepeater_items' },
        _map(this.items, repeatItems1.bind(this))
    ]));
}
        export const componentNames = []